// i18next-extract-mark-ns-start products-sabr-load

import { SABR_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductSabrPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							SABR can import a casing stiffness matrix to take into
							account the flexure of the casing and cross coupling of
							forces between bearings.
						</p>
						<p>
							SABR can export bearing loads for further finite element
							analysis of casings or complex shafts. &nbsp;The export
							can include various levels of detail from 5 degree of
							freedom forces at a single bearing point to distributed
							loads on bearing raceways. &nbsp;These can be imported
							directly into FEARCE or Abaqus.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>Casing stiffness matrix import</li>
							<li>
								Bearing force export for finite element analysis
							</li>
							<li>
								Force vector export mapped onto automatically
								generated mesh of bearing outer race – visualisation
								in FEARCE
							</li>
						</ul>
					</Trans>
				</div>

				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{SABR_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductSabrPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-sabr-load", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/sabr-hero.jpg" }) {
		...imageBreaker
	}
}
`;
